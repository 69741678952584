import { get } from './http'

export const hotSearch = (params) => {
  const url = '/mobile/index/hot_search'
  return get(url, params)
}

export const search = (params) => {
  const url = '/mobile/index/search'
  return get(url, params)
}
